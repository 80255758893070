import React from 'react';
import {
  chakra,
  Center,
  Container,
  Heading,
  HStack,
  Link,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaTwitter } from 'react-icons/fa';

function App() {
  return (
    <Container p={6}>
      <Center>
        <VStack my={20} spacing={16}>
          <Heading
            as="h1"
            size="4xl"
            bgImage="linear-gradient(to right,#BDA5FF,#7FFFE8)"
            bgClip="text"
          >
            Cape.xyz
          </Heading>
          <Text fontSize="2xl" color="gray.400">
            <chakra.span color="white">
              Cape is Canva for smart contracts.
            </chakra.span>
            &nbsp; We’re designing no-code smart contract lego blocks
            so complex contracts can be constructed out of simple
            composable pieces.
          </Text>
          <HStack spacing={8}>
            <Link href="https://twitter.com/cape_xyz" isExternal>
              <Icon as={FaTwitter} color="gray.500" w={8} h={8} />
            </Link>
          </HStack>
        </VStack>
      </Center>
    </Container>
  );
}

export default App;
